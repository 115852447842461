import { datadogRum } from "@datadog/browser-rum";

const scriptTag = document.getElementById("datadog-script");
const gitHash = scriptTag.dataset.gitHash;
const environment = scriptTag.dataset.environment;
const applicationId = scriptTag.dataset.applicationId;
const clientToken = scriptTag.dataset.clientToken;
const area = scriptTag.dataset.area;


datadogRum.init({
  applicationId: applicationId,
  clientToken: clientToken,
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site: "datadoghq.eu",
  service: "freeagent",
  env: environment,
  version: gitHash,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: "1.0.0",
  sessionSampleRate: 5,
  sessionReplaySampleRate: 0,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask",
  trackSessionAcrossSubdomains: true,
  useSecureSessionCookie: true,
});

if (area) {
  datadogRum.setGlobalContextProperty("site-area", area);
}
